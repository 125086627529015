import { SET_HOME_APIS, SET_LAYOUTS, SET_SCROLL_POSITION } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  scrollPosition: 0,
  homeApisData: null,
  layouts: {
    ...DEFAULT_DATA,
  },
};
const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_APIS:
      return {
        ...state,
        homeApisData: {
          isApiCallInProgress: false,
          ...action.value,
        },
      };
    case SET_LAYOUTS:
      return {
        ...state,
        layouts: {
          isApiCallInProgress: false,
          ...action.value,
        },
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.value,
      };
    default:
      return state;
  }
};

export default HomeReducer;
