import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import Root from "./Root";
import { FcmMessageProvider } from "./providers/FcmMessageProvider";
import * as ServiceWorker from './ServiceWorkerCore';
import pjson from '../package.json';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FcmMessageProvider>
    <Provider store={store}>
      <Root />
    </Provider>
  </FcmMessageProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

try {
  // Register worker
  ServiceWorker.register(pjson.version);
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('Service Worker Failed', e);
}

